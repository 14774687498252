import React from 'react';
import Layout from 'components/Layout';
import PlainPage from 'components/PlainPage';
import { useIntl } from 'gatsby-plugin-react-intl';

export default function Submitted(props) {
    let intl = useIntl();
    const content =
        '<div style="padding: 120; flex: 1; textAlign: center; height: 100%;">' +
        `<h1>${intl.formatMessage({ id: 'submitted-title' })}</h1>` +
        `<p>${intl.formatMessage({ id: 'submitted-subtitle' })}</p>` +
        '<p>' +
        `${intl.formatMessage({ id: 'kenra-redirect-body-1' })} ` +
        '<a href="\\" style="text-decoration: underline;">' +
        `${intl.formatMessage({ id: 'kenra-redirect-body-2' })}` +
        '</a>' +
        '</p>' +
        '<img src="/images/notfound.png" alt="" style="max-height: 450;" />' +
        '</div>';

    return (
        <Layout>
            <PlainPage content={content} />
        </Layout>
    );
}
