import React from 'react';

import { Spacing, Container } from '../kenra-storybook/index';

export default function PlainPage({ content, fullWidth }) {
    if (fullWidth) {
        return (
            <>
                <Spacing removeSpaceBottom>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </Spacing>
            </>
        );
    }
    return (
        <>
            <Spacing removeSpaceBottom>
                <Container dangerouslySetInnerHTML={{ __html: content }} />
            </Spacing>
        </>
    );
}
